@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('./fonts/Montserrat-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('./fonts/Montserrat-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./fonts/Poppins-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./fonts/Poppins-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Poppins', sans-serif;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Montserrat', sans-serif;
}

.header-wrapper {
  position: relative;
  @apply py-4 lg:px-10 xl:px-6;
}

.header-curve {
  @apply absolute -top-[100px] left-0 w-full lg:overflow-hidden leading-none -z-[1] bg-primary h-[350px] sm:h-[300px] lg:h-auto lg:bg-transparent;
}

.header-curve .desktop-curve {
  width: calc(100% + 1.3px);
  @apply hidden lg:relative lg:!block lg:h-[450px];
}

.header-curve .mobile-curve {
  @apply absolute -bottom-[60px] left-0 w-full h-[64px] lg:!hidden;
}

@layer components {
  .cookie-banner {
    @apply md:rounded-md md:border fixed flex items-center justify-center bottom-0 md:bottom-4 md:left-2/4 md:-translate-x-[50%] mx-auto md:max-w-lg w-full bg-white py-2 px-4 border-t border-gray-200;
  }
}

@layer utilities {
  .label {
    @apply text-base block mb-1;
  }
  .input {
    @apply w-full px-4 py-1.5 rounded bg-white;
  }
  select.input {
    @apply appearance-none bg-[url('img/dropdown-caret.png')] bg-no-repeat bg-right bg-contain;
  }
  .group {
    @apply mb-4;
  }
  .button {
    @apply px-6 py-1.5 bg-primary text-white rounded-lg hover:bg-black;
  }
  .req {
    @apply text-red-500 inline-block mx-1;
  }
  .error-message {
    @apply text-red-500 text-sm block mt-1;
  }
  .entry-content {
    @apply text-base md:text-lg leading-relaxed mb-8;
  }
  .entry-title {
    @apply mb-6 text-3xl font-bold leading-none md:mb-8 md:text-4xl;
  }
  .static-wrapper {
    @apply relative py-10 lg:py-20;
  }
  .section-title {
    @apply text-2xl font-bold leading-none mt-6 mb-3;
  }
  .large-title {
    @apply text-4xl font-bold leading-none mt-12 mb-6;
  }
  .static-wrapper ul {
    @apply list-disc pl-6;
  }
  .static-wrapper ul li {
    @apply mb-4 text-lg leading-relaxed;
  }
}

